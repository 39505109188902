
export const environment = {
  production: true,
  baseUrl: 'https://transport.bonjourexpress.com',
  appname: 'BONJOUR EXPRESS',
  domain:'bonjourexpress.com',
  phone:'',
  logo:'assets/layout/images/logo-bonjour@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
  show_Montant:1
};



/*



export const environment = {
  production: true,
  baseUrl: 'https://transport.odd.com.tn',
  appname: 'ODD',
  domain:'odd.com.tn',
  phone:'',
  logo:'assets/layout/images/odd.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
  show_Montant:1
};


export const environment = {
  production: true,
  baseUrl: 'https://transport.chahin-express.com',
  appname: 'CHAHIN',
  domain:'chahin-express.com',
  phone:'',
  logo:'assets/layout/images/logo-chahin.png',
  font:'/assets/fonts/',
  count_multiple:false,
  ssl: 'https',
  show_Montant:1
};



export const environment = {
  production: true,
  baseUrl: 'https://transport.tiktak-transport.com',
  appname: 'TikTak',
  domain:'tiktak-transport.com',
  phone:'',
  logo:'assets/layout/images/logo-tiktak.png',
  font:'/assets/fonts/',
  count_multiple:false,
  ssl: 'https',
  show_Montant:0
};


export const environment = {
  production: true,
  baseUrl: 'https://transport.kamatcho-express.com',
  appname: 'KAMATCHO SERVICES',
  domain:'kamatcho-express.com',
  phone:'',
  logo:'assets/layout/images/logo-kamatcho@2x.png',
  font:'/assets/fonts/',
  count_multiple:false,
  ssl: 'https',
  show_Montant:1
};




export const environment = {
  production: true,
  baseUrl: 'https://transport.goodex.tn',
  appname: 'GOODEX',
  domain:'goodex.tn',
  phone:'',
  logo:'assets/layout/images/goodex.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
  show_Montant:0
 
};




export const environment = {
  production: true,
  baseUrl: 'https://transport.airms-express.com',
  appname: 'AIR MS',
  domain:'airms-express.com',
  phone:'',
  logo:'assets/layout/images/logo-airmexpress@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
  show_Montant:1
};








export const environment = {
  production: true,
  baseUrl: 'https://transport.solva.tn',
  appname: 'Solva',
  domain:'solva.tn',
  phone:'',
  logo:'assets/layout/images/solva.png',
  font:'/assets/fonts/',
  count_multiple:false,
  ssl: 'https',
  show_Montant:0

};





export const environment = {
  production: true,
  baseUrl: 'https://transport.odd.com.tn',
  appname: 'ODD',
  domain:'odd.com.tn',
  phone:'',
  logo:'assets/layout/images/odd.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
  show_Montant:0
};














export const environment = {
  production: true,
  baseUrl: 'http://transport.helloparcels.tn',
  appname: 'Hello',
  domain:'helloparcels.tn',
  phone:'',
  logo:'assets/layout/images/logo-hello@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'http',
  show_Montant:0
};






export const environment = {
  production: true,
  baseUrl: 'https://transport.airmexpress.com',
  appname: 'AIRM EXPRESS',
  domain:'airmexpress.com',
  phone:'',
  logo:'assets/layout/images/logo-airmexpress@2x.png',
  font:'/assets/fonts/',
  count_multiple:false,
  ssl: 'https',
  show_Montant:0
};




export const environment = {
  production: true,
  baseUrl: 'https://transport.carthage-express.com',
  appname: 'CARTHAGE EXPRESS',
  domain:'carthage-express.com',
  phone:'',
  logo:'assets/layout/images/carthage.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
  show_Montant:0

};




export const environment = {
  production: true,
  baseUrl: 'https://transport.mds-colis.com',
  appname: 'MDS COLIS',
  domain:'mds-colis.com',
  phone:'',
  logo:'assets/layout/images/logo-mds.png@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
};





export const environment = {
  production: true,
  baseUrl: 'https://transport.logestiko.com',
  appname: 'LOGESTIKO',
  domain:'logestiko.com',
  phone:'',
  logo:'assets/layout/images/logo-logestiko@2x.png',
  count_multiple:false,
  font:'/assets/fonts/',
  ssl: 'https',
};


export const environment = {
  production: true,
  baseUrl: 'https://transport.2412ib.com',
  appname: '2412IB',
  domain:'2412ib.com',
  phone:'',
  logo:'assets/layout/images/logo-2412@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
};




export const environment = {
  production: true,
  baseUrl: 'https://transport.colis-sud.com',
  appname: 'Colis Sud',
  domain:'colis-sud.com',
  phone:'',
  logo:'assets/layout/images/logo-sud@2x.png',
  font:'/assets/fonts/',
  count_multiple:false,
  ssl: 'https'
};


export const environment = {
  production: true,
  baseUrl: 'https://transport.hk-rapid.com',
  appname: 'HK Rapid',
  domain:'hk-rapid.com',
  phone:'',
  logo:'assets/layout/images/logo-hk@2x.png',
  font:'/assets/fonts/',
  count_multiple:false,
  ssl: 'https'
};










export const environment = {
  production: true,
  baseUrl: 'https://transport.sys-delivery.com',
  appname: 'SYS DELIVERY',
  domain:'sys-delivery.com',
  phone:'',
  logo:'assets/layout/images/logo-jo@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
};
export const environment = {
  production: true,
  baseUrl: 'http://transport.mania.tn',
  appname: 'Mania Test',
  domain:'mania.tn',
  phone:'',
  logo:'assets/layout/images/logo-jo@2x.png',
  font:'/assets/fonts/',
  count_multiple:false,
  ssl: 'http',
};


export const environment = {
  production: true,
  baseUrl: 'https://transport.rapido-ashtar.com',
  appname: 'Rapido Ashtar',
  domain:'rapido-ashtar.com',
  phone:'',
  logo:'assets/layout/images/logo-ashtar@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
};



export const environment = {
  production: true,
  baseUrl: 'https://transport.zaineb-delivery.com',
  appname: 'Zaineb Delivery',
  domain:'zaineb-delivery.com',
  phone:'',
  logo:'assets/layout/images/logo-zaineb@2x.png',
  font:'/assets/fonts/',
  count_multiple:false,
  ssl: 'https',
};
export const environment = {
  production: true,
  baseUrl: 'http://transport.chronoexpress.tn',
  appname: 'Chrono Express',
  domain:'chronoexpress.tn',
  phone:'',
  logo:'assets/layout/images/logo-chrono@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'http',
};


export const environment = {
  production: true,
  baseUrl: 'http://transport.alamana-transport.tn',
  appname: 'AL AMANA',
  domain:'alamana-transport.tn',
  phone:'',
  logo:'assets/layout/images/logo-amana@2x.png',
  count_multiple:true,
    font:'/assets/fonts/',
  ssl: 'http',
};


export const environment = {
  production: true,
  baseUrl: 'https://transport.intime-livraison.com',
  appname: 'IN TIME DELIVERY',
  domain:'intime-livraison.com',
  phone:'',
  logo:'assets/layout/images/logo-intime@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
}

export const environment = {
  production: true,
  baseUrl: 'https://transport.expert-colis.tn',
  appname: 'Exprt Colis',
  domain:'expert-colis.tn',
  phone:'',
  logo:'assets/layout/images/logo-expert@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
};











export const environment = {
  production: true,
  baseUrl: 'https://transport.mbidelivery.com',
  appname: 'MBI Delivery',
  domain:'mbidelivery.com',
  phone:'',
  logo:'assets/layout/images/logo-mbi@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
};




















export const environment = {
  production: true,
  baseUrl: 'https://transport.madox-express.com',
  appname: 'Madox Express',
  domain:'madox-express.com',
  phone:'',
  logo:'assets/layout/images/logo-meilleur@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
};






export const environment = {
  production: true,
  baseUrl: 'http://transport.top-delivery.tn',
  appname: 'TOP DELIVERY',
  domain:'top-delivery.tn',
  phone:'',
  logo:'assets/layout/images/logo-top@2x.png',
  count_multiple:true,
    font:'/assets/fonts/',
  ssl: 'http',
};

export const environment = {
  production: true,
  baseUrl: 'https://transport.rapido-transport.tn',
  appname: 'Rapido Transport',
  domain:'rapido-transport.tn',
  phone:'',
  logo:'assets/layout/images/logo-amana@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
};




export const environment = {
  production: true,
  baseUrl: 'http://transport.meilleur-express.com',
  appname: 'Meilleur Express',
  domain:'meilleur-express.com',
  phone:'',
  logo:'assets/layout/images/logo-meilleur@2x.png',
  count_multiple:false,
    font:'/assets/fonts/',
  ssl: 'https',
};





*/